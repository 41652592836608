<template>
  <div class="footer">
    <p class="row">
      <span>Copyright © 2021-</span>
      <span>晴天的博客All Rights Reserved</span>
      <span>Powered by Qing Tian</span>
      <span class="gongan">
        <img src="@/assets/images/gongan.png" alt="" />
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36082702000167">遂川网安备36082702000167号</a>
      </span>
      <span>
        <a href="http://beian.miit.gov.cn/" target="_blank">赣ICP备2021005899号-2</a>
      </span>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>