<template>
  <div class="rightContent">
    <!-- 随机标签云 -->
    <div class="tags same">
      <h4 class="navBar">
        <i class="iconfont icon-biaoqian"></i>
        随机标签云
      </h4>
      <div class="tags_wrapper pd15">
        <a
          v-for="item in tags"
          :key="item.id"
          :style="{ color: randomColor(), 'font-size': randomSize() }"
          @click="getArticleByTags(item.name)"
          >{{ item.name }}</a
        >
      </div>
    </div>

    <!-- 最近热门 -->
    <div class="hots same mt20">
      <h4 class="navBar">
        <i class="iconfont icon-remen"></i>
        最近热门
      </h4>
      <ul class="hots_wrapper">
        <li v-for="item in hots" :key="item.id" class="noWrapDot">
          <a class="noWrapDot" @click="toDetail(item.id)">{{ item.title }}</a>
        </li>
      </ul>
    </div>

    <!-- 友情链接 -->
    <div class="friend_url same mt20">
      <h4 class="navBar">
        <i class="iconfont icon-lianjie"></i>
        友情链接
      </h4>
      <ul class="friend_url_wrapper">
        <li v-for="item in friendUrl" :key="item.id">
          <a :href="item.blogUrl" target="_blank">{{ item.blogName }}</a>
        </li>
      </ul>
    </div>

    <!-- 回到顶部 -->
    <go-top></go-top>
  </div>
</template>

<script>
import tags from "@/api/tags";
import article from "@/api/article";
import friendUrl from "@/api/friendUrl";
import GoTop from './GoTop.vue'

import { mapActions } from "vuex";
export default {
  data() {
    return {
      tags: [],
      hots: [],
      friendUrl: [],
    };
  },
  components: {
    GoTop
  },
  created() {
    this.getTags();
    this.getFriendUrl();
    this.getArticleByHot();
  },
  methods: {
    ...mapActions(["getArticleByTags", "toDetail"]),
    randomColor() {
      const r = Math.ceil(Math.random() * (255 - 100)) + 100;
      const g = Math.ceil(Math.random() * (255 - 100)) + 100;
      const b = Math.ceil(Math.random() * (255 - 100)) + 100;
      return `rgb(${r},${g},${b})`;
    },
    randomSize() {
      return Math.floor(Math.random() * 30) + 12 + "px";
    },
    async getTags() {
      const res = await tags.getTags();
      const data = res.data.sort(() =>{
        return Math.random() - 0.5;
      }).slice(0,10)
      this.tags = data;
    },
    async getFriendUrl() {
      const res = await friendUrl.getFriendUrl();
      this.friendUrl = res.data;
    },
    async getArticleByHot() {
      const res = await article.getArticleByHot();
      this.hots = res;
    },
  },
};
</script>

<style>
</style>