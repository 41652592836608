import axios from 'axios';

const instance = axios.create({
    baseURL: '/api/'
});

instance.interceptors.request.use(function(config) {
    return config;
})

// 响应拦截
instance.interceptors.response.use(function(response) {
    return response.data.data;
})


export default instance;