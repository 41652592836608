<template>
  <div class="block">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[6, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    props: {
        pageSize: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        }
    },
  methods: {
    ...mapMutations(['handleScrollTop']),
    handleSizeChange(val) {
      this.$emit('changeSize', val);
    },
    handleCurrentChange(val) {
      this.$emit('changePage', val);
      this.handleScrollTop();
    },
  },
};
</script>

<style lang='less'>
.block,
.el-pagination {
  width: 97%;
}
.el-pagination{
  overflow-x: scroll;
  &::-webkit-scrollbar{
    display: none;
    cursor: pointer;
  }
}
</style>