import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}


const routes = [
  {
    path:'/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/sitemap',
    component: () => import('@/views/Sitemap.vue')
  },
  {
    path: '/about',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/comment',
    component: () => import('@/views/Comment.vue')
  },
  {
    path: '/detail/:id',
    component: () => import('@/views/Detail.vue')
  },
  {
    path: '/tagMapBlog/:tag',
    component: () => import('@/views/TagMapBlog.vue')
  }
]

const router = new VueRouter({
  routes
})


export default router
