import axios from './index';

export default {
    getArticle() {
        return axios.get('article')
    },
    getArticleByHot() {
        return axios.get('article/hots')
    },
    getArticleByPage(params) {
        return axios.get('article/page', { params })
    },
    getArticleByTags(params) {
        return axios.get('article/tags', { params })
    },
    getArticleDetail(id) {
        return axios.get('article/id?id='+id)
    },
    updateArticle(params) {
        return axios.put('article', params)
    },
}