import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    el: '',
    header: '',
    status: 'hide',
    timer: null,
    rollTimer: null,
    lock: false,    // 默认没锁
  },
  mutations: {
    whiteHeader(state, el) {  // 将header dom 存入
      state.header = el;
    },
    goTop(state, el) {
      // console.log(el);
      state.el = el;
      let count = 0;
      el.onscroll = (e) => {
        if (state.lock) {
          return;
        }
        // 如果当前滚动条滚动超过100px
        if (el.scrollTop >= 100) {  // 回到顶部出现
          if (state.timer) { return }
          state.header.style.backgroundColor = '#000';
          state.timer = setInterval(() => {
            if (count === 1) {
              state.status = 't2';
            } else if (count === 0) {
              state.status = 't0';
            }
            count = (count + 1) % 2;
          }, 1000);
        } else {    // 滚动条滚动值小于100px
          clearInterval(state.timer)
          state.timer = null;
          state.status = 'hide';
          state.header.style.backgroundColor = '#2f20214f';
        }
      }
    },
    handleGoTop(state, el) {    // 点击回到顶部
      el.onclick = (e) => {
        const distance = Math.round(window.innerHeight - (window.innerHeight / 10.6));
        if (e.layerY > distance && e.layerY < window.innerHeight) {
          clearInterval(state.timer)
          state.timer = null;
          state.status = 'hide';
          state.lock = true;
          this.commit('handleScrollTop');
        }
      }
    },
    // 将滚动条移动到顶部
    handleScrollTop(state) {
      if (state.rollTimer) { return;}
      let start = state.el.scrollTop;
      let end = 0;
      let speed = Math.round(start / (500 / 16));    // 固定500毫秒执行完
      state.rollTimer = setInterval(() => {
        if (start - speed <= end) {
          start = end;
          clearInterval(state.rollTimer)
          state.rollTimer = null;
          state.lock = false;
        }
        start = start - speed;
        state.el.scrollTop = start;
      }, 16);
    }
  },
  actions: {
    getArticleByTags({ commit }, tags) {
      router.push({
        path: '/tagMapBlog/' + tags,
      });
    },
    toDetail({ commit }, id) {  // 去文章详情页
      router.push({
        path: '/detail/' + id,
      })
    }
  },
  modules: {
  }
})
