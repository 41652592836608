<template>
  <div id="app" ref="app">
    <!-- 顶部nav -->
    <my-header></my-header>
    <div class="container">
      <div class="content">
        <!-- 内容 -->
        <router-view></router-view>
      </div>
      <right-content></right-content>
    </div>
    <!-- 底部 -->
    <my-footer></my-footer>
  </div>
</template>

<script>
import MyHeader from './views/base/Header.vue'
import RightContent from './views/base/RightContent.vue'
import MyFooter from './views/base/Footer.vue'
import { mapMutations } from 'vuex'

export default {
  components: {
    MyHeader,
    RightContent,
    MyFooter,
  },
  mounted() {
    this.goTop(this.$refs.app)
  },
  methods: {
    ...mapMutations(['goTop'])
  }
}
</script>

<style>
</style>