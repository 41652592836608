<template>
  <ul class="articles">
    <li class="article_list mt20 pd20" v-if="articleList.length === 0">暂无文章</li>
    <li class="article_list mt20" v-for="item in articleList" :key="item.id">
      <div class="article pd20" @click="toDetail(item.id)">
        <h4 class="article_title noWrapDot">
          <a class="noWrapDot" href="">
            {{ item.title }}
          </a>
        </h4>
        <p class="article_content" v-html="item.content">
        </p>
      </div>

      <div class="article_info">
        <span class="publish_time">
          <i class="el-icon-time"></i>
          发布时间：{{ item.createdAt }}</span
        >丨 <span class="">
          <i class="el-icon-view"></i>
          浏览量：{{ item.views }}
          </span
        >丨
        <span class="tag">
          <i class="el-icon-price-tag"></i>
          Tag: <a @click="getArticleByTags(item.tags)">{{ item.tags }}</a>
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        articleList: {
            type: Array,
            required: true
        }
    },
    methods: {
        ...mapActions(['getArticleByTags', 'toDetail'])
    }
};
</script>

<style>
</style>