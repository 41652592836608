<template>
  <div class="header" ref="header">
    <div class="nav">
      <h3><router-link to="/home">晴天个人博客 | 技术博客</router-link></h3>
      <div class="options" @click="handleChange">
        <i class="el-icon-s-operation"></i>
      </div>
      <ul :class="{ items: true, open: open }">
        <li :class="{ active: router === '/home' }">
          <router-link to="/home"
            ><i class="iconfont icon-shouye"></i>首页</router-link
          >
        </li>
        <li :class="{ active: router === '/sitemap' }">
          <router-link to="/sitemap"
            ><i class="iconfont icon-ditu"></i>地图</router-link
          >
        </li>
        <li :class="{ active: router === '/about' }">
          <router-link to="/about"
            ><i class="iconfont icon-guanyu"></i>关于</router-link
          >
        </li>
        <li :class="{ active: router === '/comment' }">
          <router-link to="/comment"
            ><i class="el-icon-chat-dot-round"></i>留言</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      router: "/home",
      open: false,
    };
  },
  mounted() {
    this.whiteHeader(this.$refs.header);
  },
  watch: {
    $route(to) {
      this.router = to.path;
      this.open = false;
      this.handleScrollTop();
    },
  },
  methods: {
    ...mapMutations(["whiteHeader", "handleScrollTop"]),
    handleChange() {
      this.open = !this.open;
    },
  },
};
</script>

<style>
.items li i {
  margin-right: 5px;
}
</style>