<template>
  <div>
    <!-- 每日一句 -->
    <div class="every_day pd20">
      <p class="name" :style="{ color: randomColor }">
        每日一句（{{ everyDay.date }}）<i class="iconfont icon-xinbaniconshangchuan-"></i>
      </p>
      <p class="english_text">{{ everyDay.englishText }}</p>
      <p class="chinese_text">
        <span>{{ everyDay.chineseText }}</span>
        <span class="author">———— {{ everyDay.author }}</span>
      </p>
    </div>

    <!-- 文章 -->
    <article-list :articleList="articleList"></article-list>
    <div class="page mt20">
      <Page :pageSize="pageSize" :currentPage="currentPage" :total="total" @changeSize="changeSize" @changePage="changePage"></Page>
    </div>
  </div>
</template>

<script>
import everyDay from "@/api/everyDay.js";
import article from "@/api/article.js";
import { mapActions } from "vuex";
import ArticleList from './base/ArticleList.vue'
import Page from './base/Page.vue'
export default {
  data() {
    return {
      count: 0,
      everyDay: {},
      articleList: [],
      currentPage: 1,
      pageSize: 6,
      total: 0
    };
  },
  components: {
    ArticleList,
    Page
  },
  created() {
    this.getEveryDay();
    this.getArticle();
  },
  methods: {
    ...mapActions(["getArticleByTags", 'toDetail']),
    async getEveryDay() {
      const res = await everyDay.getEveryDay();
      this.everyDay = res;
    },
    async getArticle() {
      const params = {
        limit: this.pageSize,
        page: this.currentPage
      }
      const res = await article.getArticleByPage(params);
      this.total = res.count;
      this.articleList = res.data;
    },
    changeSize(val) {
      this.pageSize = val;
      this.getArticle();
    },
    changePage(val) {
      this.currentPage = val;
      this.getArticle();
    }
  },
  computed: {
    randomColor() {
      const r = Math.ceil(Math.random() * 255);
      const g = Math.ceil(Math.random() * 255);
      const b = Math.ceil(Math.random() * 255);
      return `rgb(${r},${g},${b})`;
    },
  },
};
</script>

<style>
.page{
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 0;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.name{
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
}
</style>