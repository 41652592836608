<template>
  <!-- <el-backtop target="#app" :bottom="100"> -->
  <div>
    <div
      v-show="innerWidth > 768"
      :class="{
        goTop: true,
        hide: status === 'hide',
        t0: status === 't0',
        t2: status === 't2',
      }"
      ref="goTop"
    ></div>
    <div class="go_top" v-show="innerWidth <= 768">
      <el-backtop target="#app" :bottom="100">
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #f2f5f6;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #1989fa;
              border-radius: 50%;
            }
          "
        >
          UP
        </div>
      </el-backtop>
    </div>
  </div>

  <!-- </el-backtop> -->
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      innerWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapState(["status"]),
  },
  mounted() {
    this.handleGoTop(this.$refs.goTop);
    this.initEvent();
  },
  methods: {
    ...mapMutations(["handleGoTop"]),
    initEvent() {
      window.addEventListener('resize', () => this.innerWidth = window.innerWidth, false)
    }
  },
};
</script>

<style scoped lang="less">
// .el-backtop {
//   width: 70px;
//   height: 100%;
//   background-color: transparent;
//   border-radius: 0%;
//   bottom: 100%;
//   transition: bottom 0.5s linear;
// }
</style>
